.search-form__form {
  padding: 70px 0px 54px;
}

.search-form__fieldset {
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
}

.search-form__input {
  display: block;
  width: 100%;
  height: 72px;
  margin: 0;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.11;
  border: 1.5px solid #AAAAAA;
  color: #A0A0A0;
  opacity: 0.7;
  transition: .3s;
}

.search-form__input:focus {
  outline: none;
  opacity: 1;
}

.search-form__submit {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 52px;
  background: #3456F3;
  border-radius: 5px;
  border: none;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;
  transition: opacity .3s linear;
}

.search-form__submit:hover {
  opacity: .6;
  cursor: pointer;
}

.search-form__submit_disabled {
  background: #EBEBEB;
  transition: unset;
  cursor: unset;
}

.search-form__text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.38;
}

.search-form__error {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12.1px;
  min-height: 22px;
  color: #FF3055;
  margin: 0;
}


/* стили для планшета */
@media screen and (max-width: 768px) {
  .search-form__form {
    padding: 62px 0px 54px;
  }
}


/* стили для смартфона */
@media screen and (max-width: 320px) {
  .search-form__fieldset {
    margin: 3px 0 5px;
  }
  .search-form__input {
    padding-left: 16px;
    height: 56px;
    font-size: 16px;
    line-height: 1;
  }
  .search-form__submit {
    top: 6px;
    width: 80px;
    height: 44px;
  }
  .search-form__fieldset_type_for-checkbox {
    display: flex;
  }
  .search-form__text {
    font-size: 11px;
    line-height: 1.64;
  }

}