/* .movies-card-list__list {
  list-style-type: none;
  padding: 0;
  margin: 65px 0 125px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 28px;
} */

.movies-card-list__button {
  display: block;
  margin: 0 auto;
  background: #F9F9F9;
  cursor: pointer;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  transition: opacity .3s linear;
}

.movies-card-list__button:hover {
  opacity: .6;
}

/* стили для планшета */
/* @media screen and (max-width: 768px) {
  .movies-card-list__list {
    margin: 75px 0 110px;
    grid-template-columns: repeat(2, fit-content(342px));
  }
} */


/* стили для смартфона */
/* @media screen and (max-width: 320px) {
  .movies-card-list__list {
    margin: 45px 0 110px;
    grid-template-columns: 1fr;
  }
} */