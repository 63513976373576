.portfolio__title {
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1.11;
  color: #A0A0A0;
  margin: 100px 0 0;
}

.portfolio__container {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.portfolio__item {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: inherit;
  text-decoration: inherit;
  transition: opacity .3s linear;
}

.portfolio__link::after {
  content: "";
  background: url(../../images/portfolio-link-pointer.svg) no-repeat center;
  width: 29px;
  height: 60px;
  display: block;
}

.portfolio__link:hover {
  opacity: .6;
}

.portfolio__item_underlined {
  border-bottom: 1px solid #E8E8E8;
}

.portfolio__title-link {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.67;
}


/* стили для планшета */
@media screen and (max-width: 768px) {
  .portfolio {
    padding: 0px 50px;
  }
  .portfolio__title {
    margin: 90px 0 0;
  }
  .portfolio__link {
    align-items: flex-end;
  }
  .portfolio__title-link {
    font-size: 28px;
    line-height: 1.79;
    letter-spacing: -0.04em;
  }
}


/* стили для смартфона */
@media screen and (max-width: 360px) {
  .portfolio {
    padding: 0px 18px;
  }
  .portfolio__title {
    margin: 70px 0 0;
    font-size: 14px;
    line-height: 20px;
  }
  .portfolio__container {
    margin-top: 20px;
  }
  .portfolio__title-link {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: -0.04em;
  }
  .portfolio__link::after {
    width: 18px;
    height: 28px;
  }
}