.login {
    max-width: 396px;
    margin: 70px auto 0px;
  }

  .login__logo-link {
    text-decoration: none;
    transition: opacity .3s linear;
  }

  .login__logo-link:hover {
    opacity: .6;
  }

  .login__title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 36px 0;
  }

  .login__form-fieldset {
    margin: 0;
    padding: 0;
    border: none;
    margin-bottom: 200px;
  }

  .login__form-label {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #A0A0A0;
  }

  .login__form-input {
    display: block;
    width: 100%;
    padding: 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 2px;
  }

  .login__form-input:focus {
    outline: none;
    border-bottom: 1px solid #3456F3;
  }

  .login__form-input:invalid {
    border-bottom: 1px solid #FF3055;
  }

  .login__form-input-error {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12.1px;
    min-height: 22px;
    color: #FF3055;
    margin: 0;
  }

  .login__form-button {
    display: block;
    width: 100%;
    height: 45px;
    background: #3456F3;
    border: none;
    border-radius: 3px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    transition: opacity .3s linear;
  }

  .login__form-button_disabled {
    background: #EBEBEB;
    transition: unset;
  }

  .login__form-button:hover {
    cursor: pointer;
    opacity: .6;
  }

  .login__form-button_disabled:hover {
    cursor: unset;
    opacity: unset;
  }

  .login__form-register-container {
    text-align: center;
    margin-bottom: 70px;
  }

  .login__form-span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
    margin-right: 6px;
  }

  .login__form-link {
    text-decoration: none;
    color: #3456F3;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    transition: opacity .3s linear;
  }

  .login__form-link:hover {
    opacity: .6;
  }


  /* стили для планшета */
@media screen and (max-width: 768px) {
  .login {
    padding-top: 222px;
  }
  .login__logo {
    margin-top: 8px;
  }
}


  /* стили для смартфона */
  @media screen and (max-width: 320px) {
    .login__logo {
      display: block;
      margin: 56px auto 0px;
    }
    .login__title {
      margin: 50px 0 0px;
      text-align: center;
    }
    .login__form {
      padding: 0 20px;
    }
    .login__form-fieldset {
      margin: 75px 0 290px;
    }
    .login__form-input {
      padding: 4px 0 10px;
      margin-bottom: 18px;
    }
    .login__form-button {
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 15px;
    }
    .login__form-span {
      font-size: 12px;
      line-height: 15px;
    }
    .login__form-link {
      font-size: 12px;
      line-height: 15px;
    }
  }