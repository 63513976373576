.techs {
  background: #F5F5F5;
}

.techs__content-title {
  font-weight: normal;
  font-style: normal;
  font-size: 50px;
  line-height: 1.16;
  text-align: center;
  margin: 83px 0 26px;
  letter-spacing: -0.04em;
}

.techs__text {
  text-align: center;
  max-width: 460px;
  min-height: 40px;
  margin: 0 auto 100px;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1.43;
}

.techs__elements-container {
  display: grid;
  /* grid-template-columns: repeat(7, minmax(84px, 90px)); */
  grid-template-columns: repeat(auto-fit, minmax(84px, 90px));
  /* grid-template-rows: minmax(57px, 60px); */
  gap: 10px;
  justify-content: center;
  padding-bottom: 100px;
}

.techs__element {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background: #E8E8E8;
}


/* стили для планшета */
@media screen and (max-width: 768px) {
  .techs {
    padding: 0px 50px;
  }
  .techs__title-container {
    height: 125px;
  }
  .techs__content-title {
    margin: 83px 0 20px;
  }
  .techs__text {
    margin: 0 auto 80px;
    font-size: 12px;
    line-height: 1.5;
  }
  .techs__elements-container {
    padding-bottom: 88px;
  }
  .techs__element {
    height: 57px;
    font-size: 12px;
    line-height: 15px;
  }
}


/* стили для смартфона */
@media screen and (max-width: 320px) {
  .techs {
    background: unset;
    padding: 0px 18px;
  }
  .techs__title-container {
    height: 77px;
  }
  .techs__content-title {
    margin: 58px 0 26px;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.04em;
  }
  .techs__text {
    margin: 0 auto 40px;
    font-size: 11px;
    line-height: 1.45;
    letter-spacing: -0.04em;
  }
}
