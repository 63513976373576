.footer {
  padding-top: 96px;
}

.footer__title {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #A0A0A0;
  margin: 80px 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E8E8E8;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer__copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
}

.footer__links-container {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.footer__link-item {
  margin-right: 20px;
}

.footer__link-item:last-of-type {
  margin-right: 0px;
}

.footer__link {
  color: inherit;
  text-decoration: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  transition: opacity .3s linear;
}

.footer__link:hover {
  opacity: .6;
}


/* стили для планшета */
@media screen and (max-width: 768px) {
.footer {
    padding-top: 63px;
  }
}


/* стили для смартфона */
@media screen and (max-width: 392px) {
  .footer {
    padding-top: 48px;
  }
  .footer__title {
    font-size: 12px;
    line-height: 15px;
  }
  .footer__container {
    margin-top: 25px;
    flex-direction: column;
  }
  .footer__links-container {
    order: -1;
  }
  .footer__links-container {
    flex-direction: column;
    align-items: center;
  }
  .footer__link-item {
    margin-right: unset;
    margin-bottom: 10px;
  }
  .footer__link {
    font-size: 12px;
    line-height: 15px;
  }
  .footer__copyright {
    margin-top: 17px;
    font-size: 12px;
    line-height: 15px;
  }
}
