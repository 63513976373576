.movies-card {
  background: #FDFDFD;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.movies-card__title-container {
  display: flex;
  padding: 18px 18px;
  justify-content: space-between;
  align-items: center;
}

.movies-card__title {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
}

.movies-card__duration {
  margin: 0;
  color: #A0A0A0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.38;
}

.movies-card__link {
  transition: opacity .3s linear;
}

.movies-card__link:hover {
  opacity: .6;
}

.movies-card__button {
  display: block;
  width: 100px;
  height: 29px;
  background: #F9F9F9;
  border-radius: 30px;
  margin: 18px auto;
  border: none;
  transition: opacity .3s linear;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 11px;
}

.movies-card__button:hover {
  cursor: pointer;
  opacity: .6;
}

.movies-card__image {
  /* display: block; */
  width: 100%;
}

.movies-card__button_type_saved {
  background: #FF3055;
}

.movies-card__button-icon_type_saved {
  width: 7px;
  height: 7px;
  background: url(../../images/saved-button-icon.svg) no-repeat center;
  margin: 0 auto;
}

.movies-card__button-icon_type_delete {
  width: 7px;
  height: 7px;
  background: url(../../images/delete-button-icon.svg) no-repeat center;
  margin: 0 auto;
}


/* стили для смартфона */
@media screen and (max-width: 320px) {
  .movies-card__title-container {
    padding: 22px 14px 14px;
  }
  .movies-card__title {
    font-size: 12px;
    line-height: 1.5;
  }
  .movies-card__duration {
    font-size: 10px;
    line-height: 1.8;
  }
}