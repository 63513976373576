.navigation-landing {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
}

.navigation-landing__item {
  width: 76px;
  height: 32px;
  margin-right: 30px;
  border-radius: 3px;
  transition: opacity .3s linear;
}

.navigation-landing__item_signin {
  background: #2BE080;
  color: black;
}

.navigation-landing__item:last-of-type {
  margin-right: 0px;
}

.navigation-landing__item:hover {
  cursor: pointer;
  opacity: .6;
}

.navigation-landing__button {
  display: block;
  color: inherit;
  text-decoration: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-internal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: calc( 231px + 50px + 100px );
}

.navigation-internal__links-container {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.navigation-internal__item {
  margin-right: 20px;
}

.navigation-internal__item:last-of-type {
  margin-right: 0;
}

.navigation-internal__link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: inherit;
  transition: opacity .3s linear;
}

.navigation-internal__link:hover {
  opacity: .6;
}

.navigation__link_active {
  font-weight: 500;
}

.navigation-internal__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  border-radius: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: inherit;
  background: #F9F9F9;
  transition: opacity .3s linear;
}

.navigation-internal__button:hover {
  opacity: .6;
}

.navigation-internal__button_light {
  background: transparent;
  border: 2px solid white;
}

.navigation-internal__profile-icon {
  margin-right: 5px;
}

/* .navigation-internal__button_active {
  font-weight: 500;
} */

.navigation-burger-menu {
  display: none;
  background: url(../../images/burger-icon.svg) no-repeat center;
  width: 44px;
  height: 44px;
  transition: opacity .3s linear;
}

.navigation-burger-menu:hover {
  cursor: pointer;
  opacity: .6;
}

.navigation-burger-menu_light {
  background: url(../../images/burger-icon-light.svg) no-repeat center;
}

/* стили для планшета */
@media screen and (max-width: 768px) {
  .navigation-burger-menu {
    display: block;
  }
  .navigation-internal {
    display: none;
  }
  .navigation-landing__item {
    width: 76px;
    height: 32px;
    margin-right: 30px;
    border-radius: 3px;
  }
}


/* стили для смартфона */
@media screen and (max-width: 320px) {
  .navigation-landing__button {
    font-size: 10px;
    line-height: 1.6;
  }
  .navigation-landing__item {
    width: 54px;
    height: 26px;
    margin-right: 14px;
  }
  .navigation-burger-menu {
    width: 40px;
    height: 43px;
  }
}
